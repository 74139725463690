.text-sm {
    font-size: 12px;
}

.btn-success {
    background-color: #01D2A1 !important;
    border: 1px solid #01D2A1 !important;
}

.btn-success:hover {
    background-color: #00af8e !important;
    border: 1px solid #01D2A1 !important;
}

.btn-outline-success {
    background-color: #fff !important;
    border: 1px solid #01D2A1 !important;
    color: #01D2A1 !important;
}

.btn-outline-success:hover {
    background-color: #01D2A1 !important;
    border: 1px solid #01D2A1 !important;
    color: #fff !important;
}

.shadow-product {
    box-shadow: 0 4px 60px rgba(13, 89, 239, 0.06);
}

.text-green {
    color: #01D2A1 !important;
}

.first-page-bg {
    background-image: url("https://plumbill.com/images/bgfirstmobile.png");
    background-repeat: no-repeat;
    -moz-background-size: 100%; /* Firefox 3.6+ */
    -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
    -o-background-size: 100%; /* Opera 9.6+ */
    background-size: 100%; /* Современные браузеры */
}



@media (min-width: 576px) and (max-width: 771px) {
    .first-page-bg {
        background-position-y: -12rem ;
    }
}

@media (min-width: 771px) and (max-width: 992px) {
    .first-page-bg {
        background-position-y: -16rem ;
    }
}

@media (min-width: 992px) {
    .first-page-bg {
        background-image: url("https://plumbill.com/images/bgfirst.png");
    }
}




.second-bg {
    background-image: url("https://plumbill.com/images/second.png");
    background-repeat: no-repeat;
    -moz-background-size: 100%; /* Firefox 3.6+ */
    -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
    -o-background-size: 100%; /* Opera 9.6+ */
    background-size: 100%; /* Современные браузеры */
}

.form-bg {
    background-image: url("https://plumbill.com/images/bg-form.png");
    background-repeat: no-repeat;
    -moz-background-size: cover; /* Firefox 3.6+ */
    -webkit-background-size: cover; /* Safari 3.1+ и Chrome 4.0+ */
    -o-background-size: cover; /* Opera 9.6+ */
    background-size: cover; /* Современные браузеры */
}

.form-floating > label, input {
    margin-left: 1rem;
    margin-right: 1rem;
}

.py2 {
    padding-top: 0.7rem !important;
    padding-bottom: 0.7rem !important;
}

.form-social {
    background-color: #43187D !important;
    color: #fff !important;
    border: 0 !important;
}

.form-social:hover {
    background-color: #01D2A1 !important;
    color: #fff !important;
    border: 0 !important;
}

.bg-purple {
    background-color: #660C95;
}